import React, { useState, useEffect, lazy, Suspense } from 'react';
import { Routes, Route, useLocation, Navigate, useNavigate } from 'react-router-dom';
import SplashPage from './SplashPage';
import Gallery from './Gallery';
import AboutPage from './AboutPage';
import Header from './Header';
import NotFound from './NotFound';
import { loadProjects } from './utils/dataLoader';
import { initViewportHeight } from './utils/viewportHeight';
import { AuthProvider } from './utils/AuthContext';
import Loading from './components/Loading';
import ProtectedRoute from './components/ProtectedRoute';
import './App.scss';
import { HelmetProvider, Helmet } from 'react-helmet-async';
import { config, routes, getRentalUrl } from './utils/routingConfig';
import EventPage from './EventPage';

const HeaderWrapper = ({ projects, currentProject, onProjectSelect }) => {
  const location = useLocation();
  const hostname = window.location.hostname;
  const isRentalSubdomain = hostname.startsWith('rent.');
  const isAdminRoute = location.pathname.startsWith('/admin');
  const isEventPage = location.pathname.startsWith('/events/');
  const isAboutPage = location.pathname === '/about';

  // Don't render header for rental subdomain or admin routes
  if (isRentalSubdomain || isAdminRoute) {
    return null;
  }

  const isHomePage = location.pathname === '/';
  if (isHomePage) {
    return null;
  }

  // For event pages and about page, use the about project
  const projectToShow = (isEventPage || isAboutPage) ? projects.find(p => p.id === 'about') : currentProject;

  if (!projects || projects.length === 0 || !projectToShow) {
    return null;
  }

  return (
    <Header
      projects={projects}
      project={projectToShow}
      onProjectSelect={onProjectSelect}
      showBackToTimeline={isEventPage}
    />
  );
};

// Only load rental components if we're on the rental subdomain
const LoginPage = React.lazy(() => import('./components/rental/LoginPage'));
const RentalDashboard = React.lazy(() => import('./components/rental/RentalDashboard'));
const MakeReservation = React.lazy(() => import('./components/rental/MakeReservation'));
const CreateAgreement = React.lazy(() => import('./components/rental/CreateAgreement'));

function App() {
  const [currentProject, setCurrentProject] = useState(null);
  const [projects, setProjects] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const location = useLocation();
  const navigate = useNavigate();
  const hostname = window.location.hostname;
  const isRentalSubdomain = hostname.startsWith('rent.');

  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      if (!isRentalSubdomain) {
        const projectsData = await loadProjects();
        setProjects(projectsData);
        if (!currentProject && projectsData.length > 0) {
          setCurrentProject(projectsData[0]);
        }
      }
      setIsLoading(false);
    };
    fetchData();
  }, [currentProject, isRentalSubdomain]);

  useEffect(() => {
    if (currentProject && !isRentalSubdomain) {
      document.documentElement.style.setProperty('--project-theme-color', currentProject.themeColor);
    }
  }, [currentProject, isRentalSubdomain]);

  const handleProjectSelect = (projectId) => {
    const selected = projects.find(p => p.id === projectId);
    setCurrentProject(selected);
  };

  const showHeader = location.pathname !== '/';

  useEffect(() => {
    initViewportHeight();
  }, []);

  useEffect(() => {
    if (location.pathname.startsWith('/work/')) {
      const projectId = location.pathname.split('/work/')[1];
      if (projectId) {
        navigate(`/gallery/${projectId}`, { replace: true });
      } else {
        navigate('/gallery', { replace: true });
      }
    }
  }, [location.pathname, navigate]);

  useEffect(() => {
    console.log('Current hostname:', hostname);
    console.log('Is rental subdomain:', isRentalSubdomain);
    console.log('Current path:', location.pathname);
  }, [hostname, isRentalSubdomain, location.pathname]);

  const renderRentalRoutes = () => (
    <>
      <Route path={routes.login} element={<LoginPage />} />
      <Route
        path="/"
        element={
          <ProtectedRoute>
            <RentalDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.rental}
        element={
          <ProtectedRoute>
            <RentalDashboard />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.makeReservation}
        element={
          <ProtectedRoute>
            <MakeReservation />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.editReservation}
        element={
          <ProtectedRoute>
            <MakeReservation editMode={true} />
          </ProtectedRoute>
        }
      />
      <Route
        path={routes.createAgreement}
        element={
          <ProtectedRoute>
            <CreateAgreement />
          </ProtectedRoute>
        }
      />
      <Route path="*" element={<Navigate to="/" replace />} />
    </>
  );

  if (isLoading) {
    return <Loading />;
  }

  return (
    <HelmetProvider>
      <AuthProvider>
        <div className={`app-container ${isRentalSubdomain ? 'rental-app' : ''}`} style={{
          backgroundColor: isRentalSubdomain
            ? '#0a0a0a'
            : (currentProject?.themeColor || '#091115')
        }}>
          <HeaderWrapper
            projects={projects}
            currentProject={currentProject}
            onProjectSelect={handleProjectSelect}
          />
          <main className={`main-content ${showHeader ? 'with-header' : ''}`}>
            <Suspense fallback={<Loading />}>
              <Routes>
                {/* Admin routes - these should be handled first */}
                <Route
                  path={routes.admin}
                  element={<Navigate to="/" replace />}
                />

                {/* Then handle subdomain-specific routes */}
                {config.isRentalSubdomain ? (
                  renderRentalRoutes()
                ) : (
                  // Main domain routes
                    <>
                      <Route path={routes.home} element={<SplashPage onEnter={() => handleProjectSelect(projects[0]?.id)} />} />
                    <Route path={routes.rental} element={<Navigate to={getRentalUrl()} replace />} />
                    <Route path="/rental/*" element={<Navigate to={getRentalUrl()} replace />} />
                      <Route
                      path="/gallery"
                      element={projects.length > 0 ? <Navigate to={`/gallery/${projects[0]?.id}`} replace /> : null}
                      />
                      <Route
                      path="/gallery/:projectId"
                      element={
                        <Gallery
                          projects={projects}
                          currentProject={currentProject}
                          setCurrentProject={setCurrentProject}
                        />
                      }
                    />
                    <Route
                      path="/about"
                      element={
                        <AboutPage data={projects.find(p => p.id === 'about')} />
                      }
                    />
                      <Route
                        path="/events/:eventSlug"
                        element={
                          <EventPage data={projects.find(p => p.id === 'about')} />
                        }
                      />
                  </>
                )}
                <Route path="*" element={<NotFound />} />
              </Routes>
            </Suspense>
          </main>
        </div>
      </AuthProvider>
    </HelmetProvider>
  );
}

export default App;
