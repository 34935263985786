import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import { getImageUrl } from './utils/imageUtils';
import { sortEventsByDate } from './utils/eventUtils';
import './EventPage.scss';
import 'swiper/css';
import 'swiper/css/pagination';
import MetaTags, { getEventMetaData } from './components/MetaTags';

// Extracted reusable event content component
export const EventContent = ({ event, isFullPage = false }) => {
  const [imageUrls, setImageUrls] = useState([]);
  const eventSlug = event.title.toLowerCase().replace(/[^a-z0-9]+/g, '-');

  useEffect(() => {
    const loadImages = async () => {
      if (event.preview_images?.length > 0) {
        const urls = await Promise.all(
          event.preview_images.map(image => getImageUrl(image))
        );
        setImageUrls(urls.filter(url => url !== null));
      }
    };
    loadImages();
  }, [event.preview_images]);

  return (
    <div className={`event-content ${isFullPage ? 'full-page' : ''}`}>
      <div className="event-text-content">
        <div className="event-metadata">
          <div className="event-date-location">
            <span className="event-date">{event.date}</span>
            <span className="event-location">@ {event.location}</span>
            {event.time && <span className="event-time">// {event.time}</span>}
          </div>
          {isFullPage ? (
            <h1 className="event-title">{event.title}</h1>
          ) : (
            <Link to={`/events/${eventSlug}`} className="event-title">{event.title}</Link>
          )}
        </div>

        <div className="event-description">
          {event.description}
        </div>

        {event.link && event.link !== "Unavailable" && (
          <a href={event.link} target="_blank" rel="noopener noreferrer" className="event-link">
            read more on {new URL(event.link).hostname.replace('www.', '')} <span className="link-icon">↗</span>
          </a>
        )}
      </div>

      {imageUrls.length > 0 && (
        <div className="event-gallery">
          <Swiper
            modules={[Pagination]}
            spaceBetween={0}
            slidesPerView={1}
            pagination={{ clickable: true }}
            grabCursor={imageUrls.length > 1}
            loop={imageUrls.length > 1}
            className="event-swiper"
            observer={true}
            observeParents={true}
            watchOverflow={true}
            autoHeight={true}
          >
            {imageUrls.map((url, index) => (
              <SwiperSlide key={index}>
                <div className="image-container">
                  <img
                    src={url}
                    alt={`${event.title} at ${event.location} - Image ${index + 1}`}
                    loading="lazy"
                    draggable="false"
                  />
                </div>
              </SwiperSlide>
            ))}
          </Swiper>
        </div>
      )}
    </div>
  );
};

const EventNavigation = ({ event, allEvents }) => {
  const [prevImageUrl, setPrevImageUrl] = useState(null);
  const [nextImageUrl, setNextImageUrl] = useState(null);

  // Sort events by date, newest first
  const sortedEvents = sortEventsByDate(allEvents);
  const currentIndex = sortedEvents.findIndex(e => e.title === event.title);

  // Find next and previous events (newer = prev, older = next)
  const prevEvent = currentIndex > 0 ? sortedEvents[currentIndex - 1] : null;
  const nextEvent = currentIndex < sortedEvents.length - 1 ? sortedEvents[currentIndex + 1] : null;

  useEffect(() => {
    const loadPreviewImages = async () => {
      if (prevEvent?.preview_images?.[0]) {
        const url = await getImageUrl(prevEvent.preview_images[0]);
        setPrevImageUrl(url);
      }
      if (nextEvent?.preview_images?.[0]) {
        const url = await getImageUrl(nextEvent.preview_images[0]);
        setNextImageUrl(url);
      }
    };
    loadPreviewImages();
  }, [prevEvent, nextEvent]);

  const getEventSlug = (event) => event.title.toLowerCase().replace(/[^a-z0-9]+/g, '-');

  return (
    <div className="event-navigation">
      {prevEvent && (
        <Link to={`/events/${getEventSlug(prevEvent)}`} className="event-nav-link prev">
          {prevImageUrl && (
            <div className="nav-image">
              <img src={prevImageUrl} alt={prevEvent.title} loading="lazy" />
            </div>
          )}
          <div className="nav-content">
            <span className="nav-label">prev</span>
            <span className="nav-title">{prevEvent.title}</span>
            <span className="nav-date">{prevEvent.date}</span>
          </div>
        </Link>
      )}
      {nextEvent && (
        <Link to={`/events/${getEventSlug(nextEvent)}`} className="event-nav-link next">
          {nextImageUrl && (
            <div className="nav-image">
              <img src={nextImageUrl} alt={nextEvent.title} loading="lazy" />
            </div>
          )}
          <div className="nav-content">
            <span className="nav-label">next</span>
            <span className="nav-title">{nextEvent.title}</span>
            <span className="nav-date">{nextEvent.date}</span>
          </div>
        </Link>
      )}
    </div>
  );
};

const EventItem = ({ event }) => (
  <EventContent event={event} isFullPage={false} />
);

const EventPage = ({ data }) => {
  const { eventSlug } = useParams();
  const navigate = useNavigate();

  const event = data?.events?.find(e =>
    e.title.toLowerCase().replace(/[^a-z0-9]+/g, '-') === eventSlug
  );

  useEffect(() => {
    if (!event) {
      navigate('/about');
    }
  }, [event, navigate]);

  if (!event) return null;

  return (
    <>
      <MetaTags {...getEventMetaData(event)} />
      <div className="event-page">
        <EventContent event={event} isFullPage={true} />
        <EventNavigation event={event} allEvents={data.events} />
      </div>
    </>
  );
};

export default EventPage;