const hostname = window.location.hostname;
const isLocalhost = hostname === 'localhost' || hostname === 'rent.localhost';

export const config = {
  isRentalSubdomain: hostname.startsWith('rent.'),
  rentalDomain: isLocalhost ? 'rent.localhost:3000' : 'rent.elodiecarstensen.com',
  mainDomain: isLocalhost ? 'localhost:3000' : 'elodiecarstensen.com',
};

export const routes = {
  home: '/',
  login: '/login',
  rental: '/rental',
  makeReservation: '/new',
  editReservation: '/edit/:id',
  createAgreement: '/create-agreement',
  gallery: '/gallery',
  about: '/about',
  admin: '/admin',
  events: '/events/:eventSlug',
};

// Helper function to get the correct route based on subdomain
export const getRoute = (name, params = {}) => {
  let route = routes[name];

  // If the route doesn't exist, return home
  if (!route) return routes.home;

  // Replace any parameters in the route
  if (params) {
    Object.keys(params).forEach(key => {
      route = route.replace(`:${key}`, params[key]);
    });
  }
  return route;
};

// Helper function to get full URL for rental subdomain
export const getRentalUrl = (path = '') => {
  return `https://${config.rentalDomain}${path}`;
};