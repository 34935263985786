import React, { useState, useEffect, useRef } from 'react';
import { getImageUrl } from './utils/imageUtils';
import './AboutPage.scss';
import { Link } from 'react-router-dom';
import { getProjectLink } from './utils/projectUtils';
import BrevoForm from './components/BrevoForm';
import { Swiper, SwiperSlide } from 'swiper/react';
import { Navigation, Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import { EventContent } from './EventPage';
import MetaTags, { getAboutMetaData } from './components/MetaTags';

const PressItem = ({ item, formatDate }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const loadImage = async () => {
      if (item.preview_image) {
        const url = await getImageUrl(item.preview_image);
        setImageUrl(url);
      }
    };
    loadImage();
  }, [item.preview_image]);

  const content = (
    <div className="press-content">
      <div className="press-header">
        <div className="press-metadata">
          <span className="press-magazine">{item.magazine}</span>
          <span className="press-date">{formatDate(item.date)}</span>
          <span className="press-title">{item.title}</span>
        </div>
      </div>

      {item.link && item.link !== "Unavailable" && (
        <span className="press-cta">
          see at {item.source || item.magazine} <span className="link-icon">↗</span>
        </span>
      )}
    </div>
  );

  const pressItem = (
    <>
      {imageUrl && (
        <div className="press-gallery">
          <div className="image-container">
            <img
              src={imageUrl}
              alt={`${item.title} - Press coverage in ${item.magazine}`}
              loading="lazy"
              draggable="false"
            />
          </div>
        </div>
      )}
      {content}
    </>
  );

  if (!item.link || item.link === "Unavailable") {
    return <div className="press-item">{pressItem}</div>;
  }

  return (
    <a
      href={item.link}
      target="_blank"
      rel="noopener noreferrer"
      className="press-item"
    >
      {pressItem}
    </a>
  );
};

const MuseCard = ({ muse }) => {
  const [imageUrl, setImageUrl] = useState(null);

  useEffect(() => {
    const loadImage = async () => {
      if (muse.image) {
        const url = await getImageUrl(muse.image);
        setImageUrl(url);
      }
    };
    loadImage();
  }, [muse.image]);

  return (
    <div className="muse-card">
      <div className="muse-image">
        {imageUrl && (
          <img
            src={imageUrl}
            alt={`${muse.name} - ${muse.profession?.join(', ')}`}
            loading="lazy"
          />
        )}
      </div>
      <div className="muse-content">
        <div className="muse-header">
          <span className="muse-name">{muse.name}</span>
          <span className="muse-role">{muse.profession?.join(', ')}</span>
        </div>
        <p className="muse-description">{muse.bio}</p>
        {muse.link && (
          <a href={muse.link} target="_blank" rel="noopener noreferrer" className="muse-instagram">
            [Instagram ↗]
          </a>
        )}
      </div>
    </div>
  );
};

const AboutPage = ({ data }) => {
  const [email, setEmail] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [backgroundImageUrl, setBackgroundImageUrl] = useState(null);
  const [expandedSections, setExpandedSections] = useState({
    about: true,
    events: true,
    awards: true,
    press: true,
    muses: true,
    collaborators: true,
    contact: true
  });
  const [selectedMuse, setSelectedMuse] = useState(null);
  const [expandedMuse, setExpandedMuse] = useState(null);
  const [expandedMuses, setExpandedMuses] = useState(new Set());

  const defaultStructuredData = {
    "@context": "https://schema.org",
    "@type": "Person",
    "name": "Elodie Carstensen",
    "url": "https://www.elodiecarstensen.com",
    "jobTitle": "Fashion Designer & Artist",
    "address": {
      "@type": "PostalAddress",
      "addressLocality": "Berlin",
      "addressCountry": "DE"
    },
    "description": "Berlin-based artist and designer creating immersive fashion experiences through collections, installations, and performances.",
    "image": "https://www.elodiecarstensen.com/assets/images/social-preview.jpg",
    "sameAs": [
      "https://www.instagram.com/elodiecarstensen/"
    ]
  };

  useEffect(() => {
    const loadBackgroundImage = async () => {
      if (data?.backgroundImage) {
        const url = await getImageUrl(data.backgroundImage);
        setBackgroundImageUrl(url);
      }
    };
    loadBackgroundImage();
  }, [data?.backgroundImage]);

  useEffect(() => {
    // Create preview portal container if it doesn't exist
    if (!document.getElementById('preview-portal')) {
      const portalContainer = document.createElement('div');
      portalContainer.id = 'preview-portal';
      document.body.appendChild(portalContainer);
    }
  }, []);

  const handleFormSubmit = (success) => {
    setChatHistory(prev => [...prev, { type: 'command', content: email }]);

    if (success) {
      setChatHistory(prev => [...prev,
      { type: 'response', content: '>> ACCESS GRANTED :: WELCOME TO THE NETWORK <<' }
      ]);
    } else {
      setChatHistory(prev => [...prev,
      { type: 'error', content: '>> ERROR :: CONNECTION FAILED <<' }
      ]);
    }
  };

  // Early return if no data is provided
  if (!data) {
    return <div>Loading...</div>;
  }

  const toggleSection = (section) => {
    setExpandedSections(prev => ({ ...prev, [section]: !prev[section] }));
  };

  const formatDate = (dateString) => {
    if (!dateString) return '';
    const months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    const date = new Date(dateString);
    if (isNaN(date)) {
      // Handle custom date formats like "September 12—15, 2024"
      return dateString.replace(/(January|February|March|April|May|June|July|August|September|October|November|December)/g,
        (match) => match.substring(0, 3));
    }
    return `${months[date.getMonth()]} ${date.getDate()}, ${date.getFullYear()}`;
  };

  const renderLink = (url, content, infoValue) => {
    if (url && url !== "Unavailable") {
      return (
        <>
          <span className="info-key">{content}</span>
          <span className="info-value">
            {infoValue}
            <a href={url} target="_blank" rel="noopener noreferrer" className="external-link-icon">
              [↗]
            </a>
          </span>
        </>
      );
    }
    return (
      <>
        <span className="info-key">{content}</span>
        <span className="info-value">{infoValue}</span>
      </>
    );
  };

  const sortByName = (a, b) => a.name.localeCompare(b.name);

  const renderSection = (title, content, level = 2) => {
    const HeadingTag = `h${level}`;
    return (
      <section className="about-section">
        <HeadingTag className="section-header" onClick={() => toggleSection(title.toLowerCase())}>
          <span className={`chevron ${expandedSections[title.toLowerCase()] ? 'expanded' : ''}`}>›</span>
          <span className="section-title">{title}</span>
        </HeadingTag>
        <div className={`section-content ${expandedSections[title.toLowerCase()] ? 'expanded' : ''}`}>
          {content}
        </div>
      </section>
    );
  };

  const renderProjectLinks = (projects) => {
    return projects.map((project, index) => {
      const projectLink = getProjectLink(project);
      if (projectLink) {
        return (
          <React.Fragment key={projectLink.id}>
            <Link to={`/gallery/${projectLink.id}`} className="project-link">
              [{project}]
            </Link>
            {index < projects.length - 1 ? ', ' : ''}
          </React.Fragment>
        );
      }
      return (
        <React.Fragment key={project}>
          {project}
          {index < projects.length - 1 ? ', ' : ''}
        </React.Fragment>
      );
    });
  };

  const toggleMuse = (museName) => {
    setExpandedMuses(prev => {
      const newSet = new Set(prev);
      if (newSet.has(museName)) {
        newSet.delete(museName);
      } else {
        newSet.add(museName);
      }
      return newSet;
    });
  };

  return (
    <>
      <MetaTags {...getAboutMetaData()} />
      <div
        className="about-page"
        style={{
          backgroundImage: backgroundImageUrl ? `url(${backgroundImageUrl})` : 'none',
          '--project-theme-color': data.themeColor || '#091115'
        }}
      >
        <div className="about-content">
          <div className="about-frame">
            <h1>{'// About Elodie Carstensen'}</h1>
            {renderSection("ABOUT", (
              <div className="about-info">
                {data.imprint && (
                  <>
                    <div className="info-line">
                      <span className="info-key">creator</span>
                      <span className="info-value">{data.imprint.name}</span>
                    </div>
                    <div className="info-line">
                      <span className="info-key">location</span>
                      <span className="info-value">{data.imprint.address?.join(', ')}</span>
                    </div>
                  </>
                )}
                {data.inquiries && (
                  <div className="info-line">
                    <span className="info-key">email</span>
                    <span className="info-value">
                      <a href={`mailto:${data.inquiries.email}`} className="email-link">
                        {data.inquiries.email}
                        <svg className="mail-icon" width="14" height="11" viewBox="0 0 14 11" fill="none" xmlns="http://www.w3.org/2000/svg">
                          <path d="M12.6 0H1.4C0.63 0 0.00699999 0.63 0.00699999 1.4L0 9.8C0 10.57 0.63 11.2 1.4 11.2H12.6C13.37 11.2 14 10.57 14 9.8V1.4C14 0.63 13.37 0 12.6 0ZM12.6 2.8L7 6.3L1.4 2.8V1.4L7 4.9L12.6 1.4V2.8Z" fill="white" />
                        </svg>
                      </a>
                    </span>
                  </div>
                )}
              </div>
            ))}
            {data.events?.length > 0 && renderSection("EVENTS", (
              <div className="events-list">
                <div className="year-content">
                  {Object.entries(data.events.reduce((acc, event) => {
                    const year = new Date(event.date).getFullYear();
                    if (!acc[year]) acc[year] = [];
                    acc[year].push(event);
                    return acc;
                  }, {})).sort(([yearA], [yearB]) => Number(yearB) - Number(yearA)).map(([year, events]) => [
                    <h3 key={`year-${year}`} className="year"><span>{year}</span></h3>,
                    ...events.map((event, eventIndex) => (
                      <EventContent
                        key={`${year}-${eventIndex}`}
                        event={event}
                        isFullPage={false}
                      />
                    ))
                  ])}
                </div>
              </div>
            ))}
            {data.awards?.length > 0 && renderSection("Awards", (
              <ul className="awards-list">
                {data.awards.map((award, index) => (
                  <li key={index} className="info-line">
                    {renderLink(award.link, award.title)}
                    <div className="info-value">
                      {award.type}, {award.year}
                      <div>{award.description}</div>
                    </div>
                  </li>
                ))}
              </ul>
            ))}
            {data.press_mentions?.length > 0 && renderSection("Press", (
              <div className="press-list">
                {data.press_mentions.map((item, index) => (
                  <PressItem
                    key={index}
                    item={item}
                    formatDate={formatDate}
                  />
                ))}
              </div>
            ))}
            {data.muses?.length > 0 && renderSection("Muses", (
              <div className="muses-grid">
                {data.muses
                  .filter(muse => !muse.hide)
                  .map((muse, index) => (
                    <MuseCard
                      key={index}
                      muse={muse}
                    />
                  ))}
              </div>
            ))}
            {data.collaborators?.length > 0 && renderSection("Collaborators", (
              <ul className="person-list">
                {data.collaborators.sort(sortByName).map((person, index) => (
                  <li key={index} className="info-line">
                    {renderLink(
                      person.instagram,
                      person.name,
                      <>
                        {person.role}
                        {person.project && person.project.length > 0 && (
                          <span className="project-links">
                            {' '}{renderProjectLinks(person.project)}
                          </span>
                        )}
                      </>
                    )}
                  </li>
                ))}
              </ul>
            ))}
            <div className="newsletter-form top-form">
              <h2>[SYSTEM::ACCESS] End of preview reached</h2>
              <div className="chat-history">
                {chatHistory.map((entry, index) => (
                  <div key={index} className={`chat-entry ${entry.type}`}>
                    {entry.type === 'command' ? `> ${entry.content}` : entry.content}
                  </div>
                ))}
              </div>
              <BrevoForm
                onSubmit={handleFormSubmit}
                email={email}
                setEmail={setEmail}
              />
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default AboutPage;
